@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --black-family: "Black Sans";
  --white: #ffffff;
  --text-color: #545454;
  --text-light: #878787;
  --bizedge-blue: #5b7ed7;
  --bizedge-light-blue: #ebeffa;
  --bizedge-yellow: #f8b636;
  --bizedge-light-yellow: #fef6e6;
  --input-field-error: #f44336;
  --button-primary-color: #2898a4;
  /* #EBEFFA  */
  /* --button-contained-primary-text-color: #ffffff; */
  /* --button-outlined-primary-text-color: #545454; */
  /* --button-upload-text-color: #878787; */
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.u-black-sans {
  font-family: var(--black-family);
}
.u-text-light {
  color: var(--text-light);
}

body > iframe {
  display: none;
}
@font-face {
  font-family: "Black Sans";
  font-styled: "black";
  font-weight: 900;
  src: url("./fonts/black-sans/blacksansblackwebfont.otf");
}
@font-face {
  font-family: "Black Sans";
  font-styled: "bold";
  font-weight: 700;
  src: url("./fonts/black-sans/blacksansboldwebfont949ef363c646d9c2456af9d67a94778f.otf");
}
@font-face {
  font-family: "Black Sans";
  font-styled: "semibold";
  font-weight: 600;
  src: url("./fonts/black-sans/blacksanssemiboldwebfont647663a8d54cb4e2551213de87a56073.otf");
}
@font-face {
  font-family: "Black Sans";
  font-styled: "medium";
  font-weight: 500;
  src: url("./fonts/black-sans/blacksanscondensedmediumwebfont.otf");
}
@font-face {
  font-family: "Black Sans";
  font-styled: "regular";
  font-weight: 400;
  src: url("./fonts/black-sans/blacksansregularwebfontd6761cbf250866e8870d97f03fa629a8.otf");
}
@font-face {
  font-family: "Black Sans";
  font-styled: "light";
  font-weight: 300;
  src: url("./fonts/black-sans/blacksanslightwebfont.otf");
}

* {
  box-sizing: border-box;
  font-family: "Black Sans";
}

body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}

body .MuiAutocomplete-listbox li {
  padding: 1rem;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
}

body .MuiAutocomplete-listbox li:hover {
  color: #2898a4;
  background: none;
}
body .MuiAutocomplete-listbox > :last-child {
  border-bottom: none;
}

.MuiTextField-root {
  margin: 1.7% 0 !important;
}

.MuiCircularProgress-root {
  display: block !important;
  margin: auto !important;
}

.MuiSelect-outlined.MuiSelect-outlined:focus {
  background: transparent;
}
.side-drawer2 .MuiDrawer-paper {
  width: 35.1% !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: #fff;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a8a8a8;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 10px;
}
iframe:last-child {
  pointer-events: auto !important;
  /* z-index: -10 !important; */
}
.mt-3 {
  margin-top: 3mm;
}

.mt-4 {
  margin-top: 4mm;
}

.mb-3 {
  margin-bottom: 3mm;
}

.me-4 {
  margin-right: 3mm;
}

.me-2 {
  margin-right: 2mm;
}

.d-flex {
  display: flex;
}

.d-flex_1 {
  flex: 1;
}

.d-flex-col {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.d-gap-xxs {
  gap: 0.25rem;
}
.d-gap-xs {
  gap: 0.5rem;
}
.d-gap-sm {
  gap: 1rem;
}
.d-gap {
  gap: 1.3rem;
}
.d-gap-lg {
  gap: 1.7rem;
}
.d-gap-xl {
  gap: 2rem;
}
.d-gap-xxl {
  gap: 3rem;
}

.debounce_input {
  width: 250px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 0 1rem;
  font-size: 15px;
}

/* .debounce_input[type=text]:focus {
  border: 2px solid #2898A4;
} */

.rdebounce_input:focus {
  border: 2px solid #2898a4;
}

.sub_page_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.ProseMirror {
  height: 150px;
}
.ProseMirror:focus {
  outline: none;
}

.ProseMirror > .ol > .ul {
  margin-left: 3rem !important;
}

a {
  color: #5b7ed7;
}

/* ul {
  padding-left: 10px;
}

*/
ul li {
  list-style: disc !important;
  list-style-position: outside !important;
}
ol li {
  list-style: decimal !important;
  list-style-position: outside !important;
}

ol,
ul {
  padding-left: 15px;
}

/* p > ol > li {
  list-style-type: disc !important;
} */
.preview-text {
  height: 20px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(84, 84, 84, 0.6) !important;
  font-size: 14px;
  font-family: var(--black-family);
}

.chat-mention {
  /* background-color: #eaf8fa; */
  border-radius: 0.4rem;
  box-decoration-break: clone;
  /* color: #545454; */
  color: #2898a4;
  padding: 0.1rem 0.3rem;
}

iframe {
  display: none !important;
}

#fc_frame #fc_widget {
  display: block !important;
}
#fc_frame,
#freshchat-container {
  pointer-events: auto !important;
  bottom: 6px;
  left: 5px;
}

p:empty::before {
  content: "";
  display: inline-block;
}
